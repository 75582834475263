<template>
  <main class="home" v-if="loaded">
    <section class="progress">
      <p class="stage">1</p>
      <div class="bar"><span></span></div>
      <p class="max">4</p>
    </section>
    <section class="header">
      <h1>Key details</h1>
      <p>Add and set key details about your event (required)</p>
    </section>
    <template v-for="(event, eventIndex) in tour.events">
      <section class="details" :key="'e' + eventIndex">
        <div
          :class="{ error: erronousSections.includes('details') }"
          id="details"
        >
          <span>
            <h5>Event name</h5>
            <DefaultTextInput
              :value="tour.name"
              @change="updateName($event)"
              :maxlength="50"
              placeholder="Add event name (min 3 chars)"
            />
            <h5 class="location">Event location</h5>
            <div class="set-location" v-if="event.city != null">
              {{ event.city.name + ", " + event.city.country }}
              <svg
                @click="removeCityFromEvent(eventIndex)"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                class="remove-button"
              >
                <g transform="translate(-320 -377)">
                  <g transform="translate(320 377)">
                    <path
                      class="a"
                      d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z"
                    />
                  </g>
                  <line class="b" x2="10" transform="translate(327 389)" />
                </g>
              </svg>
            </div>
            <div
              class="find-location"
              v-else
              :class="{ expanded: locationSearchResults.length > 0 }"
            >
              <input
                type="text"
                autocomplete="new-password"
                maxlength="50"
                placeholder="Add event city and country"
                v-if="event.city == null"
                v-model="locationSearch"
                @blur="locationSearch = ''"
                @keydown="debouncedCitySearch()"
              />
              <transition-group tag="ul" name="slide-fade">
                <li
                  v-for="location in locationSearchResults"
                  :key="location.id"
                  @click.stop="selectCity(location, eventIndex)"
                >
                  {{ location.name }}, {{ location.country }}
                </li>
              </transition-group>
            </div>
          </span>
          <span>
            <div class="rounds" ref="rounds">
              <h5>Rounds</h5>

              <div
                class="item"
                v-for="n in 8"
                :key="n"
                :class="{ active: n == event.rounds.length }"
                @click="
                  triggerRoundAdjustmentModal(
                    eventIndex,
                    event.rounds.length,
                    n
                  )
                "
              >
                {{ n }}
              </div>
            </div>
            <div class="pools-selector">
              <h5>Pools</h5>
              <div
                class="item"
                v-for="n in 5"
                :key="n"
                :class="{
                  none: n == 1,
                  active: event.rounds[0].pools.length == n,
                }"
                @click="
                  triggerPoolAdjustmentModal(
                    eventIndex,
                    event.rounds[0].pools.length,
                    n
                  )
                "
              >
                <template v-if="n == 1"> None </template>
                <template v-else>
                  {{ n }}
                </template>
              </div>
            </div>
          </span>
        </div>
      </section>
      <template v-for="(round, roundIndex) in event.rounds">
        <section
          class="pools"
          v-for="(pool, poolIndex) in round.pools"
          :key="roundIndex + 'p' + poolIndex"
        >
          <div
            class="wrapper"
            :id="'errorr' + roundIndex + 'p' + poolIndex"
            :class="{
              error: erronousSections.includes(
                'errorr' + roundIndex + 'p' + poolIndex
              ),
            }"
          >
            <header v-if="round.pools.length == 1">
              <h4>Round {{ roundIndex + 1 }}</h4>
            </header>
            <header v-else>
              <h4>
                Round {{ roundIndex + 1 }} - Pool
                {{ $store.state.poolConversion[poolIndex] }}
              </h4>
            </header>
            <div class="startwheenwrapper">
              <div class="starting-format">
                <h5>Starting format</h5>
                <DefaultRadioButton
                  :groupname="roundIndex + 'starting-format' + poolIndex"
                  value="SHOTGUN"
                  :options="{ title: 'Shotgun', value: pool.startMethod }"
                  @change="
                    $store.dispatch('updatePoolStartMethod', {
                      eventIndex: eventIndex,
                      roundIndex: roundIndex,
                      poolIndex: poolIndex,
                      startMethod: 'SHOTGUN',
                    })
                  "
                />
                <DefaultRadioButton
                  :groupname="roundIndex + 'starting-format' + poolIndex"
                  value="TEETIME"
                  :options="{
                    title: 'Teetime',
                    value: pool.startMethod,
                  }"
                  @change="
                    $store.dispatch('updatePoolStartMethod', {
                      eventIndex: eventIndex,
                      roundIndex: roundIndex,
                      poolIndex: poolIndex,
                      startMethod: 'TEETIME',
                    })
                  "
                />
              </div>
              <div class="when">
                <h5>When</h5>
                <TjingDateTimePicker
                  class="datepicker"
                  :canBeCleared="true"
                  :readOnly="false"
                  :options="poolDateTimeOptions"
                  :dateTime="pool.date"
                  @update="
                    updatePoolDateTime(
                      eventIndex,
                      roundIndex,
                      poolIndex,
                      $event
                    )
                  "
                />
              </div>
            </div>
            <div class="where">
              <h5>Where</h5>
              <StandardSmallSolidButton
                v-if="pool.layout !== null"
                class="small-button"
                :fluid="true"
                @click="
                  toggleVisibleCourseSelectors(
                    'selector' +
                      'e' +
                      eventIndex +
                      'r' +
                      roundIndex +
                      'p' +
                      poolIndex
                  )
                "
                title="Edit"
              />
              <TheBuilderLayoutPicker
                :pool="pool"
                :edit="
                  pool.layout == null ||
                  visibleCourseSelectors.includes(
                    'selector' +
                      'e' +
                      eventIndex +
                      'r' +
                      roundIndex +
                      'p' +
                      poolIndex
                  )
                "
                @apply-layout="
                  applyLayout($event, eventIndex, roundIndex, poolIndex)
                "
              />
            </div>
          </div>
        </section>
      </template>
    </template>
    <section class="nav-buttons">
      <div class="tip">
        <span>
          <Tip />
        </span>

        <p>
          Everything can be edited in the Manager after your event has been
          saved.
        </p>
      </div>
      <StandardBorderedButton
        title="Back"
        :fluid="false"
        :backwardsIcon="true"
        :desktopFluidity="true"
        @click="$router.go(-1)"
      />
      <StandardSolidButton
        class="forwards"
        title="Next"
        :fluid="false"
        :forwardIcon="true"
        :desktopFluidity="true"
        @click="validateAndGoNext()"
      />
    </section>
    <section class="start-over" @click="$refs.resetmodal.open()">
      Reset and start over
    </section>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="removepoolsmodal"
      hide-close-button
    >
      <h3>Remove pools</h3>
      <p>Are you sure you want to remove pools?</p>

      <div class="buttons">
        <StandardSolidButton
          title="Cancel"
          :fluid="false"
          @click="$refs.removepoolsmodal.close()"
        />
        <StandardBorderedButton
          title="Remove"
          :fluid="false"
          @click="adjustNumberOfPools()"
        />
      </div>
    </sweet-modal>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="removeroundsmodal"
      hide-close-button
    >
      <h3>Remove rounds</h3>
      <p>Are you sure you want to remove rounds?</p>

      <div class="buttons">
        <StandardSolidButton
          title="Cancel"
          :fluid="false"
          @click="$refs.removeroundsmodal.close()"
        />
        <StandardBorderedButton
          class="forwards"
          title="Remove"
          :fluid="false"
          @click="adjustNumberOfRounds()"
        />
      </div>
    </sweet-modal>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="resetmodal"
      hide-close-button
    >
      <h3>Reset</h3>
      <p>
        This will remove any information you have added and take you back to the
        start.
      </p>

      <div class="buttons">
        <StandardSolidButton
          title="Cancel"
          :fluid="false"
          @click="$refs.resetmodal.close()"
        />
        <StandardBorderedButton
          class="forwards"
          title="Reset"
          :fluid="false"
          @click="resetAndClose('resetmodal')"
        />
      </div>
    </sweet-modal>
  </main>
</template>

<script>
import { debounce } from "lodash";
import moment from "moment";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import DefaultTextInput from "@/components/UIElements/DefaultTextInput";
import StandardSmallSolidButton from "@/components/UIElements/StandardSmallSolidButton";
import TheBuilderLayoutPicker from "@/components/builder/TheBuilderLayoutPicker";
import DefaultRadioButton from "@/components/UIElements/DefaultRadioButton";
import TjingDateTimePicker from "@/components/TjingDateTimePicker";
import Tip from "@/assets/icons/Tip";

export default {
  name: "SingleEventMandatory",
  data() {
    return {
      loaded: false,
      poolAdjustmentValues: null,
      roundAdjustmentValues: null,
      visibleCourseSelectors: [],
      locationSearch: "",
      locationSearchResults: [],
      erronousSections: [],
    };
  },
  components: {
    StandardSolidButton,
    StandardBorderedButton,
    DefaultTextInput,
    TheBuilderLayoutPicker,
    DefaultRadioButton,
    TjingDateTimePicker,
    Tip,
    StandardSmallSolidButton,
  },
  computed: {
    poolDateTimeOptions() {
      return {
        minDateTime: moment().startOf("day"),
        maxDateTime: moment().add(1, "years"),
        timeOnly: false,
        dateOnly: false,
        mode: "standard",
      };
    },
    oneOfSettings() {
      return this.$store.getters.tour.oneOffSettings;
    },
    tour() {
      return this.$store.getters.tour.tour;
    },
  },
  methods: {
    triggerPoolAdjustmentModal(eventIndex, pools, newVal) {
      this.poolAdjustmentValues = { eventIndex, pools, newVal };

      if (pools > 1 && newVal < pools) {
        this.$refs.removepoolsmodal.open();
      } else {
        this.adjustNumberOfPools();
      }
    },
    triggerRoundAdjustmentModal(eventIndex, rounds, newVal) {
      this.roundAdjustmentValues = { eventIndex, rounds, newVal };
      if (rounds > 1 && newVal < rounds) {
        this.$refs.removeroundsmodal.open();
      } else {
        this.adjustNumberOfRounds();
      }
    },
    updatePoolDateTime(eventIndex, roundIndex, poolIndex, date) {
      this.$store.dispatch("updatePoolStartTime", {
        eventIndex,
        roundIndex,
        poolIndex,
        date,
      });
      this.removeError("errorr" + roundIndex + "p" + poolIndex);
    },
    resetAndClose(modalname) {
      this.$refs[modalname].close();

      this.$store.dispatch("resetBuilderState");
      this.$router.push({ name: "builder" });
    },
    removeError(error) {
      this.erronousSections = this.erronousSections.filter((e) => e != error);
    },
    updateName(data) {
      this.$store.dispatch("updateTourName", data);

      this.$store.dispatch("updateEventName", {
        name: data,
        eventIndex: 0,
      });

      this.removeError("details");
    },
    validateAndGoNext() {
      this.markErrors();

      if (this.erronousSections.length > 0) {
        this.$scrollTo("#" + this.erronousSections[0]);
      } else {
        this.$router.push({ name: "singleeventfeatures" });
      }
    },
    markErrors() {
      this.erronousSections = [];

      if (
        this.tour.name == null ||
        this.tour.name.length < 3 ||
        this.tour.events[0].city == null
      ) {
        this.erronousSections.push("details");
      }

      this.tour.events[0].rounds.forEach((round, roundIndex) => {
        round.pools.forEach((pool, poolIndex) => {
          if (pool.date == null || pool.layout == null) {
            this.erronousSections.push("errorr" + roundIndex + "p" + poolIndex);
          }
        });
      });
    },
    removeCityFromEvent(eventIndex) {
      this.$store.dispatch("removeCityFromEvent", eventIndex);
    },
    citySearch() {
      this.$axios({
        data: {
          query: `
          {
            citySearch(query:"${this.locationSearch}"){
              id
              name
              country
              county
              geolocation{
                lng
                lat
              }
            }
          }
          `,
        },
      })
        .then((result) => {
          if (this.locationSearch != "") {
            this.locationSearchResults = result.data.data.citySearch;
          }
        })
        .catch(() => {});
    },
    debouncedCitySearch: debounce(
      function () {
        this.citySearch();
      },
      1000,
      { maxWait: 1200 }
    ),
    selectCity(location, eventIndex) {
      this.$store.dispatch("applyCityToEvent", {
        city: location,
        eventIndex: eventIndex,
      });
      this.locationSearch = "";
      this.locationSearchResults = [];

      this.removeError("details");
    },
    toggleVisibleCourseSelectors(id) {
      if (this.visibleCourseSelectors.includes(id)) {
        this.visibleCourseSelectors = this.visibleCourseSelectors.filter(
          (selectors) => selectors != id
        );
      } else {
        this.visibleCourseSelectors.push(id);
      }
    },
    applyLayout(layout, eventIndex, roundIndex, poolIndex) {
      this.$store.dispatch("applyLayoutToPool", {
        layout: layout,
        eventIndex: eventIndex,
        roundIndex: roundIndex,
        poolIndex: poolIndex,
      });
      this.visibleCourseSelectors = [];
      this.removeError("errorr" + roundIndex + "p" + poolIndex);
    },
    adjustNumberOfPools() {
      let eventId = this.poolAdjustmentValues.eventIndex;
      let currentNumberOfPools = this.poolAdjustmentValues.pools;
      let newNumberOfPools = this.poolAdjustmentValues.newVal;

      if (currentNumberOfPools > newNumberOfPools) {
        let roundsToRemove = currentNumberOfPools - newNumberOfPools;

        for (let index = 0; index < roundsToRemove; index++) {
          this.$store.dispatch("removePoolFromRounds", { eventIndex: 0 });
        }

        this.$refs.removepoolsmodal.close();
      } else if (newNumberOfPools > currentNumberOfPools) {
        let roundsToAdd = newNumberOfPools - currentNumberOfPools;

        for (let index = 0; index < roundsToAdd; index++) {
          this.$store.dispatch("addPoolToRounds", { eventIndex: 0 });
        }
      }
    },
    adjustNumberOfRounds() {
      let eventId = this.roundAdjustmentValues.eventIndex;
      let currentNumberOfRounds = this.roundAdjustmentValues.rounds;
      let newNumberOfRounds = this.roundAdjustmentValues.newVal;

      if (currentNumberOfRounds > newNumberOfRounds) {
        let roundsToRemove = currentNumberOfRounds - newNumberOfRounds;

        for (let index = 0; index < roundsToRemove; index++) {
          this.$store.dispatch("removeRoundFromEvent", { eventIndex: 0 });
        }

        this.$refs.removeroundsmodal.close();
      } else if (newNumberOfRounds > currentNumberOfRounds) {
        let roundsToAdd = newNumberOfRounds - currentNumberOfRounds;

        for (let index = 0; index < roundsToAdd; index++) {
          this.$store.dispatch("addRoundToEvent", { eventIndex: 0 });
        }
      }
    },
  },
  mounted() {},
  beforeMount() {
    this.loaded = true;
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.start-over {
  margin-top: 64px;
  color: $strom;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  color: $dusk;
}

.nav-buttons {
  margin-top: 22px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .tip {
    padding: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: $sunrisemist;
    border: 1px solid #ffd97f;
    border-radius: 6px;
    margin-bottom: 24px;
    width: 100%;
    span {
      margin-right: 10px;
    }

    svg {
      height: 18px;
      width: 14px;
    }

    p {
      display: inline;
      margin: 0;
      font-size: 14px;
    }
  }
  button {
    width: 47%;
  }
}
.set-location {
  border: 1px solid $blizzard;
  border-radius: 6px;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  @include Gilroy-Bold;
  transition: all ease 0.3s;

  &:hover {
    border: 1px solid $midnight;
  }

  .remove-button {
    width: 24px;
    height: 24px;
    cursor: pointer;
    .a {
      fill: $dusk;
    }
    .b {
      fill: none;
      stroke: #fff;
      stroke-width: 2px;
    }
  }
}
.find-location {
  width: 100%;
  border: 1px solid $blizzard;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 15px;
  @include Gilroy-Bold;
  transition: all ease 0.3s;

  &.expanded {
    padding-bottom: 20px;
    transition: all ease 0.3s;
  }

  &:hover {
    border: 1px solid $midnight;
  }

  input {
    height: 46px;
    border: none;
    width: 100%;
    outline: none;
    padding: 0;
    transition: all ease 0.3s;

    &:focus {
      border: 0px solid transparent;
      transition: all ease 0.3s;
    }

    &::placeholder {
      @include Gilroy-Medium;
      color: $blizzard;
    }
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.3s ease;
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    transition: all 0.3s ease;
    max-height: 0;
    padding: 0px 15px;
  }

  ul {
    margin: 0;
    padding: 0;
    overflow: hidden;
    transition: all ease 0.3s;
    width: 100%;
    li {
      cursor: pointer;
      transition: all ease 0.3s;
      max-height: 50px;
      overflow: hidden;
      padding: 5px 15px;
      transition: all ease 0.3s;
      width: 100%;
      display: flex;

      &:hover {
        background-color: $mist;
        transition: all ease 0.3s;
      }
    }
  }
}

.pools {
  padding: 0 15px;
  margin-top: 16px;

  > div {
    background: white;
    border-radius: 6px;
    box-shadow: 0 0 0 1px $sleet;

    &.error {
      box-shadow: 0 0 0 2px $dusk;
    }

    header {
      @include Gilroy-Bold;
      padding: 9px 12px;
      background: $fog;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      h4 {
        font-size: 18px;
        margin: 0;
      }
    }
    h4 {
      font-size: 16px;
      margin-bottom: 0;

      span {
        @include Gilroy-Regular;
        color: $strom;
        font-size: 12px;
      }
    }

    .when {
      border-bottom: 1px solid $fog;
      margin-left: 20px;
      margin-right: 20px;
      padding-bottom: 24px;
      padding-top: 20px;

      h5 {
        margin-bottom: 12px;
      }
    }
    .starting-format {
      margin-left: 20px;
      margin-right: 20px;
      border-bottom: 1px solid $fog;
      padding-bottom: 24px;

      display: flex;
      flex-wrap: wrap;
      h5 {
        width: 100%;
        margin-bottom: 14px;
        margin-top: 24px;
        display: flex;
        align-items: center;
        > div {
          margin-left: 8px;
        }
      }
      .default-radio-buttons {
        width: 50%;
        display: inline;
      }
    }
    .where {
      position: relative;
      margin: 24px 20px 24px 20px;
      padding-bottom: 28px;
      border-bottom: 1px solid $fog;

      h5 {
        margin-bottom: 12px;
      }
      .small-button {
        position: absolute;
        right: 15px;
        top: -2px;
      }
    }
  }
}
.nav-buttons {
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  button {
    width: 47%;
  }
}
.details {
  padding: 0 15px;
  margin-top: 44px;
  span {
    width: 100%;
  }
  > div {
    background: white;
    padding: 20px 20px 28px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 10px;
    box-shadow: 0 0 0 1px $sleet;
    transition: all ease 0.3s;
    &.error {
      box-shadow: 0 0 0 2px $dusk;
    }

    h5 {
      font-size: 16px;
      margin-bottom: 12px;

      span {
        color: $blizzard;
      }

      &.location {
        margin-top: 24px;
      }
    }
  }

  h3 {
    font-size: 24px;
    margin-top: 24px;
  }
  p {
    text-align: center;
    font-size: 16px;
  }
  a {
    font-size: 14px;
    color: $strom;
    margin-top: 8px;
  }

  .rounds,
  .pools-selector {
    width: 100%;

    h5 {
      font-size: 16px;
      margin-bottom: 0;
      margin-top: 24px;
      width: 100%;
    }
    p {
      font-size: 18px;
      width: 100%;
      text-align: left;
      margin-top: 24px;
      @include Gilroy-Bold;
      margin-bottom: 0;
    }
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .item {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $blizzard;
      color: $blizzard;
      border-radius: 4px;
      margin-right: 10px;
      margin-top: 10px;
      @include Gilroy-Bold;
      transition: all ease 0.3s;

      &.active {
        border: 1px solid $twilight;
        background: $twilight;
        color: white;

        &:hover {
          color: white;
          cursor: default;
          border: 1px solid $twilight;
        }
      }

      &:hover {
        border: 1px solid $midnight;
        color: $midnight;
        cursor: pointer;
      }

      &.none {
        width: 64px;
      }
    }
  }

  .default-toggle {
    margin-top: 16px;
  }
  .feature-icon {
    height: 48px;
    width: auto;
  }
}
.progress {
  margin-top: 32px;
  background: none;
  padding: 0 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
  }

  .stage {
    font-size: 16px;
    @include Gilroy-Bold;
    margin-right: 12px;
  }
  .max {
    font-size: 16px;
    @include Gilroy-Bold;
    margin-left: 12px;
  }
  .bar {
    width: 100%;
    background: $fog;
    height: 8px;
    border-radius: 4px;

    span {
      width: 25%;
      background: $ocean;
      display: flex;
      height: 8px;
      border-radius: 4px;
    }
  }
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  h1 {
    font-size: 32px;
    @include Gilroy-Bold;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 24px;
  }
  p {
    font-size: 16px;
    text-align: center;
    margin-top: 16px;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .progress {
    display: flex;
    justify-content: center;

    .bar {
      width: 50%;
    }
  }
}

@media (min-width: 1200px) {
  .start-over {
    font-size: 16px;
  }
  .pools {
    margin-top: 32px;

    .wrapper {
      display: flex;
      flex-wrap: wrap;
      border-radius: 10px;

      .startwheenwrapper {
        width: 40%;

        .starting-format {
          padding-right: 48px;
          padding-left: 48px;
          margin: 0;
          padding-bottom: 42px;
          h5 {
            font-size: 20px;
            padding-top: 42px;
            margin-top: 0;
            margin-bottom: 24px;
          }
        }

        .when {
          padding-top: 42px;
          padding-right: 48px;
          padding-left: 48px;
          border-bottom: none;
          margin: 0 0 32px 0;

          h5 {
            font-size: 20px;
            margin-bottom: 24px;
          }
        }
      }

      .where {
        width: 60%;
        margin: 0;
        padding-left: 48px;
        border-left: 1px solid $fog;
        padding-right: 48px;

        .small-button {
          top: 38px;
          right: 48px;
        }

        h5 {
          font-size: 20px;
          padding-top: 42px;
        }
      }
    }
    div {
      header {
        padding: 10px 24px;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        h4 {
          font-size: 24px;
        }
      }
    }
  }

  .details {
    margin-top: 100px;
    > div {
      display: flex;
      flex-direction: row;
      padding: 40px 48px 52px 48px;
      border-radius: 10px;
    }

    span {
      h5 {
        font-size: 20px;
        margin-bottom: 20px;

        &.location {
          margin-top: 36px;
        }
      }
      .default-inputfield {
        width: 80%;
      }
      .set-location {
        width: 80%;
      }
      .find-location {
        width: 80%;
      }
    }
    span:first-of-type {
      width: 55%;
    }
    span:last-of-type {
      width: 45%;
    }

    .rounds {
      h5 {
        margin-top: 0;
        font-size: 20px;
        margin-bottom: 20px;
      }

      .item {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-right: 11px;
      }
    }
    .pools-selector {
      h5 {
        margin-top: 36px;
        font-size: 20px;
        margin-bottom: 20px;
      }

      .item {
        width: 48px;
        height: 48px;
        margin-top: 0px;
      }
    }
  }
  .progress {
    margin: auto;
    margin-top: 92px;
    width: 300px;
    padding: 0;
    .bar {
      width: 100%;
    }
  }
  .header {
    h1 {
      font-size: 64px;
      line-height: 64px;
      margin-top: 58px;
      margin-bottom: 0;
    }
    p {
      font-size: 18px;
      margin-top: 24px;
    }
  }
  .nav-buttons {
    margin-top: 64px;

    .tip {
      width: auto;
      order: 2;
      padding: 0 15px;
      display: flex;
      align-items: center;
      margin: 0;
      p {
        font-size: 16px;
      }
    }
    .backward {
      order: 1;
      width: auto;
    }
    .forwards {
      order: 3;
    }
  }
}
</style>
